var proxyConfig = {
    //开发环境
    dev: {
        // 服务url
        serviceUrl: 'http://175.178.7.20:6060/'
    },
  
  
    //线上环境
    prod: {
        // 服务url
        serviceUrl: document.location.protocol + "//" + document.location.host
    }
}
  
export default (process.env.NODE_ENV === 'development' ? proxyConfig.dev : proxyConfig.prod)
  