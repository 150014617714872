<template>
    <div id="app">
        <keep-alive>
        <router-view></router-view>
        </keep-alive>
    </div>
</template>

<script>

export default {
    name: 'app',
    created(){
        // this.IsPC();
        // this.$store.commit('getTenantInfo')
    },
    methods: {
        IsPC(){
            var userAgentInfo = navigator.userAgent;
            var Agents = new Array("Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod");  
            var flag = true;  
            for (var v = 0; v < Agents.length; v++) {  
                if (userAgentInfo.indexOf(Agents[v]) > 0) { flag = false; break; }  
            }  
            if(!flag){
                var pathname = window.location.pathname;
                if(pathname == '/business/wxLogin'){
                    return;
                }
                var data = {"/business/index":"/business_mobile/index", 
                            "/business/goods/goodsList":"/business_mobile/shop", 
                            "/business/member/index":"/business_mobile/member", 
                            //"/business/member/vip":"/business_mobile/vip", 
                            //"/business/member/guide":"/business_mobile/guide", 
                            //"/business/member/other_site":"/business_mobile/other_site", 
                            //"/business/member/recharge":"/business_mobile/recharge", 
                            //"/business/member/send_order":"/business_mobile/send_order", 
                            "/business/member/order":"/business_mobile/order", 
                            //"/business/member/receipt":"/business_mobile/receipt", 
                            //"/business/member/money_log":"/business_mobile/money_log", 
                            //"/business/member/check_pieces":"/business_mobile/check_pieces", 
                            //"/business/member/receive_order":"/business_mobile/receive_order", 
                            //"/business/member/edit_password":"/business_mobile/edit_password", 
                            //"/business/member/edit_member":"/business_mobile/edit_member", 
                            //"/business/member/inviter":"/business_mobile/inviter", 
                            "/business/login":"/business_mobile/login", 
                            "/business/register":"/business_mobile/register", 
                            "/business/forget_password":"/business_mobile/forget_password", 
                            "/business/m_index":"/business_mobile/index", 
                            "/business/m_login":"/business_mobile/login", 
                            "/business/m_register":"/business_mobile/register", 
                            "/business/m_forgetpassword":"/business_mobile/forget_password", 
                            "/business/m_goodsall":"/business_mobile/shop", 
                            //"/business/m_guide":"/business_mobile/guide", 
                            //"/business/m_othersite":"/business_mobile/other_site"
                            };
                var toPathName = '/business_mobile/index';
                for(var key in data){
                    if(pathname.indexOf(key) != -1){
                        toPathName = data[key];
                        break;
                    }
                }

                var host = window.location.host;
                if (host.indexOf('192')!=-1){
                    window.location.href = window.location.protocol + '//' + window.location.hostname + ':3003' + toPathName;
                }else{
                    window.location.href = window.location.protocol + '//' + host + toPathName;
                }
            }
        },
    }
}
</script>

<style>
*{
    margin: 0;
    padding: 0;
}
html,body,#app{
    font-family: "Avenir", Helvetica, Arial, sans-serif;
}
#app{
    height: 100%;
}
ul{
    list-style: none;
}
a{
    text-decoration: none;
    color: #333;
}
.clearfix::after{
    content: '';
    display: block;
    clear: both;
}
.fr{
    float: right;
}
.fl{
    float: left;
}
body,html{
    height: 100%;
}
.wrap{
    /* width: 1200px; */
    margin: 0 auto;
}
#app {
    
}
.d_flex{
    display: flex;
}
</style>
