/**
 *
 * @description 登录、获取用户信息、退出登录、清除accessToken逻辑，不建议修改
 */

import Vue from 'vue'
import {
  login,
  logout,
  getUserInfo,
  getRouterPermissions,
  getSaveCustomerMsg
} from '@/api/auth'
import {
  getAccessToken,
  removeAccessToken,
  setAccessToken,
  setFlag
} from '@/utils/accessToken'
// import {
//   resetRouter
// } from '@/router'
import { 
  title
} from '@/config'
const state = () => ({
  accessToken: getAccessToken(),
  account: '',
  userId: '',
  userName: '',
  userCode: '',
  priceChangeList: localStorage.getItem('priceChangeList') !=null?JSON.parse(localStorage.getItem('priceChangeList')):[],
  permissions: [],
  roles: [],
  flag: '',
  loginState: '已登录',
  menu: [],
  customerLists: [],
  lange:localStorage.getItem('lange') !=null?localStorage.getItem('lange'):'English',
  organizationName:localStorage.getItem('organizationName')
})
const getters = {
  accessToken: (state) => state.accessToken,
  lange: (state) => state.lange,
  organizationName: (state) => state.organizationName,
  userName: (state) => state.userName,
  userCode: (state) => state.userCode,
  userId: (state) => state.userId,
  account: (state) => state.account,
  priceChangeList: (state) => state.priceChangeList,
  permissions: (state) => state.permissions,
  roles: (state) => state.roles,
  loginState: (state) => state.loginState,
  flag: (state) => state.flag,
  menu: (state) => state.menu,
}
const mutations = {
  UPDATE_MENU(state, menu) {
    state.menu = menu
  },
  setAccessToken(state, accessToken) {
    state.accessToken = accessToken
    setAccessToken(accessToken)
  },
  setLange(state, lange) {
    state.lange = lange
   
  },
  setOrganizationName(state, OrganizationName) {
    state.OrganizationName = OrganizationName
   
  },
  setFlag(state, flag) {
    state.flag = flag
    setFlag(flag)
  },
  setAccount(state, account) {
    state.account = account
  },
  setloginState(state, loginState) {
    state.loginState = loginState
  },
  setUserName(state, userName) {
    state.userName = userName
  },
  setUserCode(state, userCode) {
    state.userCode = userCode
  },
  setUserId(state, userId) {
    state.userId = userId
  },
  setPermissions(state, permissions) {
    state.permissions = permissions
  },
  setPriceChangeList(state, priceChangeList) {
    console.log(1)
    console.log(priceChangeList)
    state.priceChangeList = priceChangeList
  },
  setRoles(state, roles) {
    state.roles = roles
  },
}
const actions = {
  getRouterList(context) {
    return new Promise((resolve, reject) => {
      let roles = context.state.roles
      getRouterPermissions(roles).then(res => {
        if (res.data && res.data.length) {
          context.commit('UPDATE_MENU', res.data)
          resolve(res)
        }
      }).catch(res => {
        reject([])
      })
    })
  },

  setloginState({
    commit
  }, loginState) {
    commit('setloginState', loginState)
  },
  setPermissions({
    commit
  }, permissions) {
    commit('setPermissions', permissions)
  },
  setPriceChangeList({
    commit
  }, priceChangeList) {
    console.log(2)
    commit('setPriceChangeList', priceChangeList)
  },
  setOrganizationName({
    commit
  }, OrganizationName) {
    commit('setOrganizationName', OrganizationName)
  },
  setLange({
    commit
  }, lange) {
    commit('setLange', lange)
  },
  async login({
    commit
  }, userInfo) {
    const {
      data,msg
    } = await login(userInfo)
   
    const accessToken = data.token
    const flag = data.flag
    if (accessToken) {
      commit('setAccessToken', accessToken)
      commit('setFlag', flag)
    } else {
      
    }
  },
  async getUserInfo({
    commit,
    state
  }) {
    const {
      data
    } = await getUserInfo()
    if (!data) {
      Vue.prototype.$baseMessage('验证失败，请重新登录...', 'error')
      return false
    }
    let {
      permissions,
      roles,
      account,
      userId,
      userCode,
      userName,
      loginRoles

    } = data
    sessionStorage.setItem('userId', userId)

    if (permissions && account && Array.isArray(permissions)) {
      commit('setPermissions', permissions)
      commit('setRoles', roles)
      commit('setAccount', account)
      commit('setUserName', userName)
      commit('setUserCode', userCode)
      commit('setUserId', userId)
      return permissions
    } else {
      Vue.prototype.$baseMessage('用户信息接口异常', 'error')
      return false
    }
  },
  async logout({
    dispatch
  }) {
    await logout()
    await dispatch('resetAccessToken')
    // await resetRouter()
    location.reload()
  },
  resetAccessToken({
    commit
  }) {
    commit('setPermissions', [])
    commit('setAccessToken', '')
    removeAccessToken()
  },
}
export default {
  state,
  getters,
  mutations,
  actions,
}
