import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import lrz from 'lrz'
import '@/plugins/element/element.js'
import '@/components/commonCss.css'

import qs from 'qs'
Vue.prototype.$qs = qs
// 环境切换配置
import proxyConfig from '@/conf/proxyConfig'
Vue.prototype.$proxyConfig = proxyConfig


Vue.prototype.$locationUrl='http://'+window.location.hostname+':8099/'
Vue.prototype.$locationViewUrl='http://'+window.location.hostname+'/'
// Vue.prototype.$locationUrl='http://192.168.1.12:8099/'
// Vue.prototype.$locationUrl='https://shop.aopiya.com:8097/'
// // // Vue.prototype.$locationUrl='http://202.96.158.115:8099/'
// Vue.prototype.$locationViewUrl='http://'+window.location.hostname+':3001'
//引入常用组件
import myPlugin from '@/components/index.js'
Vue.use(myPlugin)

//引入常用方法
//日期
import time from '@/utils/time_utils'
Vue.prototype.$time = time
//正则验证
import check from '@/utils/check_utils'
Vue.prototype.$check = check
//图片
import img_utils from '@/utils/img_utils'
Vue.prototype.$imgUtils = img_utils
//引入request
import axios from './http'
Vue.prototype.$axios = axios
import request from './utils/request'
Vue.prototype.$request = request
//过滤器加载
import {fullPath} from '@/utils/filters.js'
Vue.prototype.$fullPath = fullPath
Vue.filter('fullPath', fullPath)
//通用
import common from '@/utils/common'
Vue.prototype.$common = common

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)

Vue.config.productionTip = false

import moment from 'moment'	// 时间格式化
Vue.prototype.$moment = moment

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
